import {request as axios} from "@/util/request";
import { message } from "ant-design-vue";
import {OrderData, QueryParams} from "@/api/order/order-management";
const getUrl = require('baimahu/url-with-param');

const listAPI = {
    list:'/boss/marketing/tuan/activity',
    detail:'/boss/marketing/tuan/activity/',
    create:'/boss/marketing/tuan/activity',
    update:'/boss/marketing/tuan/activity/',
    invalid:'/boss/marketing/tuan/activity/invalid/',
}



export async function getList(query_params: QueryParams = { current: 1 }){
    const url = getUrl(listAPI.list, query_params);
    return await axios.get(url);
}


export async function getDetail(id){
    return axios.get(`${listAPI.detail}${id}`);
}

export async function setInvalidX(id){
    const res = await axios.put(`${listAPI.invalid}${id}`);
    if(res.status == 200){
        message.success("设置成功")
        return true;
    } else {
        message.error(`设置失败: ${res.message}`)
        return false;
    }
}
export async function setRemove(id){
    const res = await axios.delete(`${listAPI.invalid}${id}`);
    if(res.status == 200){
        message.success("删除成功")
        return true;
    } else {
        message.error(`删除失败`)
        return false;
    }
}

export  async function onCreate(data){
    const res = await axios.post(`${listAPI.create}`, data);
    return res
}
export  async function onUpdate(data, id){
    const res = await axios.put(`${listAPI.update}${id}`, data);
    return res
}

export interface TuanListData {
    create_time?: number;
    end_time?: number;
    id?: number;
    number?: number;
    order_count?: number;
    people_count?: number;
    sales_money?: number;
    start_time?: number;
    status?: number;
    title?: string;
    update_time?: number;
}